<template>
    <div class="lunbo">
        <el-form ref="mainForm" :model="mainForm" :rules="rules" label-width="80px">
            <el-form-item label="大标题" prop="title">
                <el-input v-model="mainForm.title" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="副标题" prop="subhead">
                <el-input v-model="mainForm.subhead" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="主图" prop="imageUrl">
                <imageUploader v-on:success="uploadSucess" :isMultiple="false" :showPreview="true"></imageUploader>
            </el-form-item>
            <el-form-item>
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            mainForm: {
                title: "",
                subhead: "",
                imageUrl: "",
            },
            rules: {
                title: [{ required: true, message: "请输入标题", trigger: "blur" }],
                subhead: [{ required: true, message: "请输入副标题", trigger: "blur" }],
                imageUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
            },
        };
    },
    filters: {
        getImageUrl: function(src, host) {
            return host + src;
        },
    },
    mounted() {},
    methods: {
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },
        uploadSucess: function(val) {
            this.mainForm.imageUrl = val;
        },
        submit: function() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.$http({
                        url: "/sys/operation/createLunbo",
                        data: {
                            title: this.mainForm.title,
                            subhead: this.mainForm.subhead,
                            image: this.mainForm.imageUrl,
                            display: 1,
                        },
                    }).then((res) => {
                        this.$message({
                            message: "操作成功",
                            type: "success",
                        });
                        this.$router.push("/operate/lunbo");
                    });
                }
            });
        },
    },
};
</script>
